import React from 'react'
import Layout from '../components/layout'

import Menu from '../components/menu'

import NameLogo from '../images/writing.png'

const IndexPage = () => (
  <Layout>
    <div className="landing">
      <img src={NameLogo} alt="Beakal Asefa" />
      <h1>
        A Full Stack Software Engineer focusing on web and cross-platform
        applications
      </h1>
      <h3><a href="mailto:beakal.asefa@gmail.com?subject=Hello%20there" rel="noopener noreferrer">beakal.asefa@gmail.com</a></h3>
    </div>
    <div>
      <Menu />
    </div>
  </Layout>
)

export default IndexPage
